export enum QuestionKeys {
  Intention = 'intention',
  AirConditioning = 'air_conditioning',
  Geometry = 'geometry',
  MechanicalVentilationSystem = 'mechanical_ventilation_system',
  Terraced = 'terraced',
  InsulatedCellar = 'insulated_cellar',
  InsulatedAttics = 'insulated_attics',
  InsulatedFloor = 'insulated_floor',
}

export enum UserKeys {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  Phone = 'phone',
  Civility = 'civility',
  Uid = 'uid',
  OptOutStatus = 'optOutStatus',
}

export enum BinaryAnswer {
  Yes = 'yes',
  No = 'no',
}

export type BinaryAwnserWithDoesntKnow = BinaryAnswer | 'doesntKnow';

export enum BinaryQuestionWithDoesntKnow {
  Yes = 'yes',
  No = 'no',
  DoesntKnow = 'doesntKnow',
}

export enum IntentionValues {
  SaleHouse = 'saleHouse',
  ReduceEnergyBill = 'reduceBill',
  Confort = 'confort',
  ReduceGasEmition = 'reduceGasEmition',
  Curiosity = 'curiosity',
}

export enum GeometryValues {
  Square = 'square',
  ElongatedOrShapedAsAnL = 'elongatedOrShapedAsAnL',
  Complex = 'complex',
}

export enum MechanicalVentilationSystemValues {
  No = 'no',
  SingleFlow = 'singleFlow',
  DoubleFlow = 'doubleFlow',
}

export enum ExistingHeatingType2Values {
  ElectricHeater = 'electricHeater',
  FireplaceInsert = 'fireplaceInsert',
  WoodStove = 'woodStove',
  WoodPelletStove = 'woodPelletStove',
  Other = 'other',
}

export enum TerracedValues {
  NotTerraced = 'notTerraced',
  TerracedOnOneSide = 'terracedOnOneSide',
  TerracedOnTwoSides = 'terracedOnTwoSides',
}

export enum GlazingTypeValues {
  SingleGlazing = 'singleGlazing',
  DoubleGlazing = 'doubleOrTripleGlazing',
}
